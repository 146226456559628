console.log("env file", process.env)
// const BASE_URI = process.env.REACT_APP_EPHARM_URL || 'http://localhost:8001/api';
// const BASE_URI = process.env.REACT_APP_EPHARM_URL || 'dev.epharmacy-api.ponea.com/api';
const BASE_URI = 'http://localhost:8001/api'
// const BASE_URI = 'https://dev.epharmacy-api.ponea.com/api'

const BASE_PHARMACY_URI = `${BASE_URI}/v2`;
console.log("BASE_PHARMACY_URI:", BASE_PHARMACY_URI)

const uris = {
  account: {
    login: `${BASE_PHARMACY_URI}/users/login`,
    changePasswordAndConfirmEmail: `${BASE_PHARMACY_URI}/identity/change-password-confirm-email`,
    logout: `${BASE_PHARMACY_URI}/identity/logout`,
    changePassword: `${BASE_PHARMACY_URI}/identity/change-password`,
    confirmEmail: `${BASE_PHARMACY_URI}/identity/confirm-email`,
  },
  users: {
    getAll: `${BASE_PHARMACY_URI}/users`,
    create: `${BASE_PHARMACY_URI}/users`,
    getSingle: (id: string) => `${BASE_PHARMACY_URI}/users/${id}`,
    update: (id: string) => `${BASE_PHARMACY_URI}/users/${id}`,
    delete: (id: string) => `${BASE_PHARMACY_URI}/users/${id}`,
    makeAdmin: `${BASE_PHARMACY_URI}/users/make-admin`,
  },
  toQuote: {
	getAll: `${BASE_PHARMACY_URI}/orders/?pageNumber=1&pageSize=10`,
  },
  quotation: {
	getAll: `${BASE_PHARMACY_URI}/quotations/`,
	create: `${BASE_PHARMACY_URI}/quotations/`,
    getSingle: (id: string) => `${BASE_PHARMACY_URI}/quotations/${id}`,
  },
  workOrder: {
    getAll: `${BASE_PHARMACY_URI}/quotations/workorders/`,
	dipatchOrder: (id: string) => `${BASE_PHARMACY_URI}/quotations/workorders/dispatch/${id}`
  },
  serviceCatalogue: {
    getAll: `${BASE_PHARMACY_URI}/service_catalogue/`,
    toggleStockedStatus: `${BASE_PHARMACY_URI}/service_catalogue/toggle_stocked`,
    saveService: `${BASE_PHARMACY_URI}/service_catalogue/update`
  },
  common: {
    myRoles: `${BASE_URI}/common/users/my-roles`,
  },
  dashboard: {
    getValues: `${BASE_URI}/services/dashboard`
  },

  addQueryParams(
    uri: string,
    queryParams: { name: string; value: string | boolean | number }[],
  ): string {
    const query = new URLSearchParams("");

    queryParams.forEach((queryParam) =>
      query.append(queryParam.name, queryParam.value.toString()),
    );

    return uri + "?" + query;
  },
};

export default uris;
